.mainContent {
    margin-left: 200px;
}

.jumbotron {
    padding-bottom: 10px;
    padding-top: 10px;
}
.header-top{
    padding: 10px;
    width: 100%;
    background-color: #0079BF;
    /* background-color: #47b3f2; */

    color: white;
    z-index: 1;
}
.header-top h1{
    margin-left: 200px;
}