.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.pagination-item {
    display: inline-block;
    padding: 8px;
    margin: 2px;
    border-radius: 4px;
    background-color: #e4e4e4;
    cursor: pointer;
}

.pagination-item.active {
    background-color: #00a0e9;
    color: #fff;
}

.pagination-item.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.pagination-item a {
    color: inherit;
    text-decoration: none;
}
.message {
    overflow: auto;
    width: 30%;
}
